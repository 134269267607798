<template>
  <div class="app-container" style=" margin-top: -8px">
    <div class="searchList">
      <el-form :inline="true" :model="queryPagination" class="demo-form-inline">
        <el-form-item label="俱乐部ID">
          <el-input
            v-model="queryPagination.clubId"
            placeholder="俱乐部ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="俱乐部名">
          <el-input
            v-model="queryPagination.name"
            placeholder="俱乐部名"
          ></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
            v-model="queryPagination.description"
            placeholder="简介"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      :height="pageHeight - 200"
      class="tableList"
    >
      <el-table-column
        prop="clubId"
        label="俱乐部ID"
        width="140px"
      ></el-table-column>
      <el-table-column prop="photoUrl" width="70px" label="头像">
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            size="small"
            :src="scope.row.photoUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="300px" label="名称"></el-table-column>
      <el-table-column
        prop="description"
        label="简介"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="numMembers"
        label="成员数"
        width="140px"
      ></el-table-column>
      <el-table-column label="操作" width="160px">
        <template slot-scope="scope">
          <el-button
            style="color: #E6A23C"
            @click="openClubDetail(scope.$index, scope.row)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top:10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible" v-if="dialogVisible" top="2vh">
      <ClubDetail :id="clubDetailId"></ClubDetail>
      <ClubMember :id="clubDetailId"></ClubMember>
    </el-dialog>
  </div>
</template>

<script>
import { getClubList } from '@/api/club.js'
import ClubDetail from '@/views/club/ClubDetail'
import ClubMember from '@/views/club/ClubMember'

export default {
  name: 'ClubList',
  components: {
    ClubDetail,
    ClubMember
  },
  data() {
    return {
      list: null,
      listLoading: true,
      queryPagination: {
        pageIndex: 1,
        pageSize: 10,
        clubId: null,
        name: null,
        description: null
      },
      count: 0,
      dialogVisible: false,
      clubDetailId: null
    }
  },
  computed: {
    pageHeight() {
      return this.$store.state.settings.windowHeight
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      try {
        const { all_count: count, items } = await getClubList(
          this.queryPagination
        )
        this.count = count
        this.list = items
      } finally {
        this.listLoading = false
      }
    },
    handleSizeChange(pageSize) {
      this.queryPagination.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryPagination.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    openClubDetail(index, row) {
      // console.log(index, row)
      this.dialogVisible = true
      this.clubDetailId = row.clubId
    }
  }
}
</script>

<style scoped>
.searchList {
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(82 63 105 / 20%);
  background: #fff;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 64px;
}
.tableList {
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(82 63 105 / 20%);
  background: #fff;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin-bottom: 15px;
}
</style>
