<template>
  <div>
    <el-descriptions title='俱乐部详情' column='3' border :labelStyle='{"width":"120px"}'>
      <!--      <el-descriptions-row>-->
      <!--        <el-descriptions-item>-->
      <!--          <img-->
      <!--            src='https://d14u0p1qkech25.cloudfront.net/club_35_9ed57f8a-ca71-4d4e-a59e-8af7f5646e23_thumbnail_250x250'>-->
      <!--        </el-descriptions-item>-->
      <!--      </el-descriptions-row>-->
      <el-descriptions-item label='头像'>
        <!--        <img-->
        <!--          :src='clubDetail.photoUrl'>-->

        <el-avatar shape='square' :size='50' :src='clubDetail.photoUrl'></el-avatar>
      </el-descriptions-item>

      <el-descriptions-item label='俱乐部ID'>
        {{ clubDetail.clubId }}
      </el-descriptions-item>
      <el-descriptions-item label='俱乐部名称'>
        {{ clubDetail.name }}
      </el-descriptions-item>

      <el-descriptions-item label='成员数'>
        {{ clubDetail.numMembers }}
      </el-descriptions-item>
      <el-descriptions-item label='是否允许被关注'>
        {{ clubDetail.isFollowAllowed }}
      </el-descriptions-item>
      <el-descriptions-item label='是否是社区'>
        {{ clubDetail.isCommunity }}
      </el-descriptions-item>
      <el-descriptions-item label='数据更新时间'>
        <a :src='clubDetail.dataTime'></a>
      </el-descriptions-item>
      <el-descriptions-item label='链接' :span='2'>
        <a :src='clubDetail.url'></a>
      </el-descriptions-item>
      <el-descriptions-item label='社区章程' :span='3'>
        {{ clubDetail.rules }}
      </el-descriptions-item>

      <el-descriptions-item label='俱乐部简介' :span='3'>
        {{ clubDetail.description }}
      </el-descriptions-item>

    </el-descriptions>
  </div>
</template>

<script>
import { getClubDetail } from '@/api/club.js'

export default {
  name: 'ClubDetail',
  props: {
    id: Number
  },
  data() {
    return {
      clubId: this.id,
      // clubId: 38,
      clubDetail: null
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.clubDetail = await getClubDetail(this.clubId)
    }
  }

}
</script>

<style scoped>

</style>
