<template>
  <div>
    <h1>俱乐部成员</h1>
    <el-table
      v-loading='listLoading'
      :data='tableData'
      element-loading-text='Loading'
      border
      fit
      highlight-current-row
      style='width: 100%'>
      <el-table-column
        prop='photoUrl'
        label='头像'
        width='100'>
        <template slot-scope='scope'>
          <el-avatar shape='square' size='small' :src='scope.row.photoUrl'></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        prop='name'
        label='用户姓名'>
      </el-table-column>
      <el-table-column
        prop='username'
        label='用户名'>
      </el-table-column>
      <el-table-column
        prop='twitter'
        label='推特用户名'>
      </el-table-column>
      <el-table-column
        prop='instagram'
        label='instagram用户名'>
      </el-table-column>
<!--      <el-table-column-->
<!--        prop='numFollowers'-->
<!--        label='粉丝数'>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop='numFollowings'-->
<!--        label='关注数'>-->
<!--      </el-table-column>-->
      <el-table-column
        prop='createTime'
        label='注册时间'>
      </el-table-column>

<!--      <el-table-column-->
<!--        prop='url'-->
<!--        width='380'-->
<!--        label='主页链接'>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-link :href='scope.row.url'>{{ scope.row.photoUrl }}</el-link>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop='dataTime'-->
<!--        label='入库时间'>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop='bio'-->
<!--        label='简介'>-->
<!--      </el-table-column>-->
    </el-table>
    <div class='block'>
      <el-pagination
        @size-change='handleSizeChange'
        @current-change='handleCurrentChange'
        :current-page.sync='queryPagination.pageIndex'
        :page-sizes='[10, 20, 50, 100]'
        :page-size='queryPagination.pageSize'
        layout='total, sizes, prev, pager, next, jumper'
        :total='count'
      >
      </el-pagination>
    </div>
  </div>

</template>

<script>
import { getClubMembers } from '@/api/club'

export default {
  name: 'clubhouseUserList',
  props: {
    id: Number
  },
  data() {
    return {
      listLoading: true,
      clubId: this.id,
      queryPagination: {
        pageIndex: 1,
        pageSize: 5
      },
      count: 0,
      tableData: null
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      try {
        const { all_count: count, items } = await getClubMembers(this.clubId, this.queryPagination)
        this.count = count
        this.tableData = items
      } finally {
        this.listLoading = false
      }
    },
    handleSizeChange(pageSize) {
      this.queryPagination.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryPagination.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    }
  }
}
</script>

<style scoped>

</style>
